import {useIntl} from 'react-intl'
import {useAuth} from '../../../../../app/modules/auth'
import {MenuItem} from '../../header/header-menus/MenuItem'
import {SidebarMenuItem} from './SidebarMenuItem'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'

const SidebarMenuMainAdmin = () => {
  const {currentUser} = useAuth()
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-white text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>

      <SidebarMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com015.svg'
      >
        <SidebarMenuItemWithSub
          to='/apps/chat/omni-management/omni'
          title='OMNI Channel'
          hasBullet={true}
        >
          <MenuItem
            to={'/apps/chat/omni-management/new?status=new'}
            title={'New Case'}
            hasBullet={true}
          />
          <MenuItem
            to={'/apps/chat/omni-management/open?status=open'}
            title={'Open Case'}
            hasBullet={true}
          />
          <MenuItem
            to={'/apps/chat/omni-management/all?status=all'}
            title={'All Case'}
            hasBullet={true}
          />
          <MenuItem
            to={'/apps/chat/omni-management/close?status=close'}
            title={'Close Case'}
            hasBullet={true}
          />
          <MenuItem
            to={'/apps/chat/omni-management/spam?status=spam'}
            title={'Spam Case'}
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>
        <SidebarMenuItemWithSub
          to='/apps/chat/flow-builder-management/'
          title='Flow Builder'
          hasBullet={true}
        >
          <MenuItem
            to={'/apps/chat/flow-builder-management/flow-builder/marketing'}
            title={'Marketing'}
            hasBullet={true}
          />
          <MenuItem
            to={'/apps/chat/flow-builder-management/flow-builder/transactional'}
            title={'Transactional'}
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>
        <SidebarMenuItem
          to='/apps/chat/broadcast-management/broadcasts'
          title='Broadcast'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/apps/chat/contact-group-management/contact-groups'
          title='Contact Group'
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/apps/crm'
        title='Setup'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com016.svg'
      >
        <SidebarMenuItem
          to='/apps/crm/channel-management/channel'
          title='Channel Management'
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-white text-uppercase fs-8 ls-1'>{`User & Role`}</span>
        </div>
      </div>
      <SidebarMenuItem
        to='/apps/user-management/users'
        icon='/media/icons/duotune/general/gen051.svg'
        title='User Management'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/apps/company-management/company'
        icon='/media/icons/duotune/general/gen051.svg'
        title='Company'
        fontIcon='bi-layers'
      />
    </>
  )
}

export default SidebarMenuMainAdmin
