import moment from 'moment'
import {useEffect} from 'react'
import {generateCurrencyFormat} from '../../../../helpers/generate-currency-format'
import {useDashboardStore} from '../states/dashboard.state'
import DashboardCardComponent from './dashboard-card.component'

const TotalDonationCardComponent = () => {
  const {totalDonation, totalDonationLoading, getTotalDonation, startDate, endDate} =
    useDashboardStore()

  useEffect(() => {
    getTotalDonation({start_date: undefined, end_date: undefined})
  }, [])

  useEffect(() => {
    if (startDate !== undefined && endDate !== undefined) {
      getTotalDonation({
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
      })
    }
  }, [startDate, endDate])

  return (
    <>
      <DashboardCardComponent
        title='Device Connected'
        color='danger'
        value={generateCurrencyFormat(totalDonation ?? 0)}
        subtitle='Total device yang terhubung'
        loading={totalDonationLoading}
      />
    </>
  )
}

export default TotalDonationCardComponent
