import moment from 'moment'
import {useEffect} from 'react'
import {useDashboardStore} from '../states/dashboard.state'
import DashboardCardComponent from './dashboard-card.component'

const TotalCustomerCardComponent = () => {
  const {totalCustomer, totalCustomerLoading, getTotalCustomer, startDate, endDate} =
    useDashboardStore()

  useEffect(() => {
    getTotalCustomer({start_date: undefined, end_date: undefined})
  }, [])

  useEffect(() => {
    if (startDate !== undefined && endDate !== undefined) {
      getTotalCustomer({
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
      })
    }
  }, [startDate, endDate])

  return (
    <>
      <DashboardCardComponent
        title='Blast'
        color='warning'
        value={totalCustomer ?? 0}
        icon={<i className={`fas fa-envelope text-warning fs-2`} style={{marginRight: 10}}></i>}
        subtitle='Total pesan blasting'
        loading={totalCustomerLoading}
      />
    </>
  )
}

export default TotalCustomerCardComponent
